import firebase from 'firebase/app';
import { v4 as uuid } from 'uuid';
import { useState, useEffect } from 'react';

export type PaymentType = {
  paymentId: string;
  userId: string;
  amount: number;
  paymentMethod: 'card';
};

export type Order = {
  id: string;
  items: any[];
  status: {
    [key: string]: boolean;
  };
  divideBill: {
    dividePrice: number;
    numberOfFriends: number;
    totalPrice: number;
    restDivision: number;
  };
  payment: string;
};

export function getOrderById(id: string) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .get();
}

export function getPaymentOrderById({ orderId, paymentId }) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .collection('payments')
    .doc(paymentId)
    .get()
    .then(res => res.data() as PaymentType);
}

export function dividePayment({ id, paymentMethod, payWithRestIfExist }) {
  const divide = firebase
    .app()
    .functions()
    .httpsCallable('dividePayment');

  return divide({ orderId: id, paymentMethod, payWithRestIfExist });
}

export function sharePayment({ orderId, paymentMethod, productIds, productsBillingsIds, name }) {
  firebase
    .app()
    .functions()
    .useFunctionsEmulator('http://localhost:5000');
  const shareBill = firebase
    .app()
    .functions()
    .httpsCallable('sharePayment');

  return shareBill({ orderId, paymentMethod, productIds, productsBillingsIds, name });
}

export async function createOrderFunction(order: Partial<Order>) {
  const createOrder = firebase
    .app()
    .functions()
    .httpsCallable('createOrder');

  return createOrder(order);
}

export function updateOrder(orderId: string, order: Partial<Order>) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .update(order);
}

function getAllPaymentOrderFn(orderId: string) {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(orderId)
    .collection('payments')
    .where('amount', '>', 0);
}

export function getAllPaymentOrder(orderId: string) {
  return getAllPaymentOrderFn(orderId)
    .get()
    .then(querySnapshot => {
      const result = [];
      querySnapshot.forEach(doc => {
        result.push({ id: doc.id, ...doc.data() });
      });
      return result as (PaymentType & { id: string })[];
    });
}

export function usePaymentOnSnapshot(orderId: string) {
  const [payments, setPayments] = useState<PaymentType[]>();
  useEffect(() => {
    return getAllPaymentOrderFn(orderId).onSnapshot(querySnapshot => {
      const p = [];
      querySnapshot.forEach(doc => {
        p.push(doc.data());
      });
      setPayments(p);
    });
  }, []);

  return payments;
}

export function useGetOrderByIdOnSnapshot(id: string) {
  const [order, setOrder] = useState<Order>();
  const [loading, setLoading] = useState<boolean>(true);
  useEffect(() => {
    return firebase
      .app()
      .firestore()
      .collection('orders')
      .doc(id)
      .onSnapshot(doc => {
        setLoading(true);
        setOrder(doc.data() as Order);
        setLoading(false);
      });
  }, []);

  return { loading, order };
}

export async function getUserOrderByName(id: string, name: string = '') {
  const doc = await firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .collection('users')
    .doc(name.toLocaleLowerCase())
    .get();
  return doc.data();
}

export function addUserNameToOrder(id: string, name: string = '') {
  return firebase
    .app()
    .firestore()
    .collection('orders')
    .doc(id)
    .collection('users')
    .doc(name.toLocaleLowerCase())
    .set({ name: name.toLocaleLowerCase() });
}