import React from 'react';
import styled from 'styled-components';
import { colors } from '@bit/kards.kards-components.helpers';
import { ReactComponent as Logo } from '../svg/Fingercross.svg';
import { ReactComponent as LogoEnsemble } from '../svg/Ensemble.svg';
import EnsembleImg from '../svg/Ensemble.svg';
import { useTheme } from 'src/modules/card/context/theme';

export function FingerCross(props) {
  const theme = useTheme();
  return <FingerCrossSVG color={colors[theme?.color?.title]} {...props} />;
}

const FingerCrossSVG = styled(Logo)`
  width: 30%;
  fill: ${props => props.color} !important;
`;

export function Ensemble(props) {
  return <EnsembleSVG st0="#b0b1b1" st1="#ffffff" {...props} />;
}

const EnsembleSVG = styled(LogoEnsemble)`
  width: 40%;
  .st0 {
    fill: ${props => props.st0} !important;
  }
  .st1 {
    fill: ${props => props.st1} !important;
  }
`;
