import React from 'react';
import { useHistory, useParams, Redirect, useLocation } from 'react-router-dom';
import { Text, Footer, PaymentMethodField } from 'src/common-ui';
import { getOrderById } from 'src/data/accessor/api';
import { useQuery } from 'src/data/hooks/useQuery';
import { toEuro } from '../utils';
import { FormikLayout } from '../components/FormikLayout';
import { FingerCross } from '../components/Logos';

export function DivideBillPaymentChoice() {
  const history = useHistory();
  const { id } = useParams();
  const { state }: any = useLocation();
  const { error, data: order } = useQuery(() => getOrderById(id));

  const dividePrice = order?.divideBill?.dividePrice;
  const restDivision = order?.divideBill?.restDivision;
  const amountToPay = state?.paidRestOfBill ? dividePrice + restDivision : dividePrice;

  if (error) return <Redirect to="/bill/divide" />;

  const submit = values => {
    if (order?.status?.S3) {
      history.push({ pathname: `/bill/divide/payment-status/${id}` });
    } else {
      history.push({
        pathname: `/bill/divide/payment/${id}`,
        state: {
          ...values,
          paidRestOfBill: Boolean(state?.paidRestOfBill),
          amountToPay,
        },
      });
    }
  };

  return (
    <FormikLayout initialValues={{ payment: 'card' }} onSubmit={submit} style={{ paddingBottom: '120px', paddingTop: 0 }}>
      <FingerCross />

      <Text fontWeigth="bold" fontSize="22px" margin="0 0 10px 0" textAlign="center">
        Payer ma part
      </Text>

      <Text margin="10px" textAlign="center">
        Montant à payer par personne
      </Text>

      <div style={{ backgroundColor: '#919193', display: 'flex', padding: '6px 26px', borderRadius: '30px' }}>
        <Text fontWeigth="800" fontSize="26px" textAlign="center" revertColor data-cy="amountToPay">
          {toEuro(amountToPay)}
        </Text>
      </div>
      <div style={{ width: '100%', padding: '0 10px' }}>
        <PaymentMethodField name="payment" />
      </div>

      <Footer type="submit" loading={!order} title="Valider" data-cy="btn-valider" />
    </FormikLayout>
  );
}
