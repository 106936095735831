import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ShareLinkComponent } from '../components/ShareLink';

export function ShareLink() {
  const history = useHistory();
  const { id: orderId } = useParams();

  const shareURL = `${window.location.origin}/bill/share/payment-choose/${orderId}`;

  const submit = () => {
    history.push(`/bill/share/payment-choose/${orderId}`);
  };

  return (
    <ShareLinkComponent
      title="Partager la note"
      info={
        <>
          Appuyez sur partager pour envoyer un lien de paiement à chaque participant.
          <br />
          Vous pourrez ensuite payer chacun votre consommation respective.
          <br />
          Les bons comptes font les bons amis!
        </>
      }
      shareURL={shareURL}
      onSubmit={submit}
    />
  );
}
