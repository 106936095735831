import React, { useState } from 'react';
import { Text, Footer } from 'src/common-ui';
import { FormikLayout } from './FormikLayout';
import { ButtonShare } from './ButtonShare';
import { Ensemble } from './Logos';

type ShareLinkProps = {
  title: string;
  info: string | React.ReactNode;
  shareURL: string;
  onSubmit: () => void;
};

export function ShareLinkComponent({ title, info, shareURL, onSubmit }: ShareLinkProps) {
  const [copied, setCopied] = useState(false);

  return (
    <FormikLayout initialValues={{}} onSubmit={onSubmit} style={{ paddingBottom: '120px' }}>
      <Ensemble style={{ marginTop: '15px' }} />

      <Text margin="25px 0" fontWeigth="bold" fontSize="22px">
        {title}
      </Text>

      <Text textAlign="center" style={{ width: '70%', marginBottom: '20px' }}>
        {info}
      </Text>

      <ButtonShare shareURL={shareURL} style={{ width: '70%', textAlign: 'center' }} onCopy={() => setCopied(true)} data-cy="share-link" />

      <Footer type="submit" disabled={!copied} title="Payer ma part" data-cy="btn-paid" />
    </FormikLayout>
  );
}
