import React from 'react';
import { DivideRoutes } from './divide/DivideRoutes';
import { ShareRoutes } from './share/ShareRoutes';

export function DivideBillRoutes() {
  return (
    <>
      <DivideRoutes />
      <ShareRoutes />
    </>
  );
}
