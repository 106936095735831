import React from 'react';
import { IonLabel, IonChip, IonIcon } from '@ionic/react';
import { closeCircle } from 'ionicons/icons';
import { useTheme } from 'src/modules/card/context/theme';

export function Chip({ product, onDelete }) {
  const theme = useTheme();
  return (
    <IonChip color={theme?.color?.title} outline>
      <IonLabel
        style={{
          width: '75px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {product.reference}
      </IonLabel>
      {!product.payed && (
        <IonIcon
          icon={closeCircle}
          onClick={e => {
            e.preventDefault();
            onDelete(product);
          }}
        />
      )}
    </IonChip>
  );
}
