import React, { useState } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { createAndConfirmPaymentIntents } from 'src/data/accessor/stripe';
import { useReadContext } from '../../card/context';
import { FormPayment } from '../../stripe';
import { useBasketContext } from 'src/modules/basket';
import { Error as ErrorComponent } from 'src/common-ui/Error';
import { useOrderPreparingContext } from 'src/modules/liveOrder/context';

const payConfirmAndCreateOrder = async ({ service, day, mode, paymentMethod, saveCard, user, ordersParameters, baskets }) => {
  const res = await createAndConfirmPaymentIntents({
    order: {
      parameters: {
        onplace_number: ordersParameters.onplace_number,
        userInformations: ordersParameters.userInformations,
        averageTime: ordersParameters.averageTime || 5,
        phone_number: user.phoneNumber,
        deliveryInstruction: ordersParameters.deliveryInstruction || null,
        delivery: ordersParameters.delivery,
        payment: ordersParameters.payment,
        mode,
      },
      userId: user.uid,
      baskets,
    },
    payment_method: paymentMethod,
    saveCard,
    preorder:
      mode === 'preorder'
        ? {
            day,
            service,
            preorderSlots: ordersParameters.preorderSlots,
          }
        : null,
  });

  return res;
};

export default function Paid() {
  const { mode, user, day, service }: any = useReadContext();
  const { followOrder }: any = useOrderPreparingContext();
  const { baskets, amount } = useBasketContext();
  const { state }: any = useLocation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const ordersParameters = {
    delivery: state?.delivery,
    onplace_number: state?.onplace_number,
    payment: state?.payment,
    preorderSlots: state?.preorderSlots,
    userInformations: state?.userInformations,
  };

  const submitRegister = async ({ paymentMethod, saveCard }) => {
    try {
      let { data }: any = await payConfirmAndCreateOrder({ service, day, mode, paymentMethod, saveCard, user, ordersParameters, baskets });
      if (data.error) throw { data: data.data, type: data.type };
      followOrder(data.data);
    } catch (e) {
      setError(e);
    }
  };

  if (
    !ordersParameters.delivery ||
    !ordersParameters.payment ||
    (mode === 'preorder' && !ordersParameters.preorderSlots) ||
    (ordersParameters.delivery === 'onplace' && !ordersParameters.onplace_number)
  )
    return <Redirect to="/" />;

  if (!baskets || baskets.length < 1) return <Redirect to="/" />;

  if (error) return <ErrorComponent error={error} resetError={() => setError(null)} />;

  return (
    <FormPayment
      variant={state?.payment}
      googlePayInfos={{
        label: 'Kards total',
        amount,
      }}
      loading={loading}
      onSubmitRegister={submitRegister as any}
      onSubmitNewCard={submitRegister as any}
    />
  );
}
