import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormPayment } from '../../stripe';
import { sharePayment } from '../../../data/accessor/api';
import { Error } from 'src/common-ui';
import { toEuro } from '../utils';

export function ShareBillPayment() {
  const history = useHistory();
  const { id: orderId } = useParams();
  const { state }: any = useLocation();
  const { productIds, amountToPay, productsBillingsIds, name } = state;
  const [error, setError] = useState<any>();

  const submit = async ({ paymentMethod }) => {
    try {
      const result = await sharePayment({
        orderId,
        paymentMethod,
        productIds,
        productsBillingsIds,
        name,
      });
      if (result.data.error) {
        setError(result.data);
        return;
      }
      history.push(`/bill/share/payment-status/${orderId}`);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  if (error) return <Error error={error} resetError={() => setError(false)} />;

  return (
    <FormPayment
      onSubmitRegister={submit as any}
      onSubmitNewCard={submit as any}
      nbProducts={productIds?.length}
      price={toEuro(amountToPay)}
    />
  );
}
