import React, { useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import FormAddNewCard from './FormAddNewCard';
import FormPaidWithNewCard from './FormPaidWithNewCard';
import FormPaidWithRegisterCard from './FormPaidWithRegisterCard';
import { useCreditCards } from '../../data/hooks/useCreditCards';
import Loading from './Loading';
import { CheckoutFormProps } from './CheckoutForm';
import { GooglePayButton, GooglePayButtonProps } from './GooglePayButton';
import { FooterProps } from 'src/common-ui';

const stripePromise = loadStripe((window as any).kardsConfiguration.stripePk);

type FormPaymentProps = {
  variant?: 'card' | 'googlePay' | 'applePay';
  googlePayInfos?: GooglePayButtonProps['googlePayInfos'];
  onSubmitRegister?: CheckoutFormProps['onSubmit'];
  onSubmitNewCard?: CheckoutFormProps['onSubmit'];
} & Partial<FooterProps>;

export function FormPayment({ variant = 'card', googlePayInfos, onSubmitRegister, onSubmitNewCard, ...props }: FormPaymentProps) {
  return (
    <Elements stripe={stripePromise}>
      {['googlePay', 'applePay'].includes(variant) && (
        <GooglePayButton googlePayInfos={googlePayInfos} onSubmit={onSubmitRegister as any} type={variant as 'googlePay' | 'applePay'}>
          <PaymentWorkFlow onSubmitRegister={onSubmitRegister} onSubmitNewCard={onSubmitRegister} {...props} />
        </GooglePayButton>
      )}
      {variant === 'card' && <PaymentWorkFlow onSubmitRegister={onSubmitRegister} onSubmitNewCard={onSubmitRegister} {...props} />}
    </Elements>
  );
}

type PaymentWorkFlowProps = {
  loading?: boolean;
  onSubmitRegister?: CheckoutFormProps['onSubmit'];
  onSubmitNewCard?: CheckoutFormProps['onSubmit'];
};

function PaymentWorkFlow({ onSubmitRegister, onSubmitNewCard, ...props }: PaymentWorkFlowProps) {
  const { data, loadingCreditCard, setData } = useCreditCards();
  const [step, setStep] = useState('paid');

  return (
    <>
      {(loadingCreditCard || props.loading) && <Loading text="Chargement de vos cartes" />}
      {step === 'paid' && !loadingCreditCard && data.length > 0 && (
        <FormPaidWithRegisterCard onSubmit={onSubmitRegister} setCreditCards={setData} creditCards={data} setStep={setStep} {...props} />
      )}
      {step === 'paid' && !loadingCreditCard && data.length === 0 && <FormPaidWithNewCard onSubmit={onSubmitNewCard} {...props} />}
      {step === 'new' && <FormAddNewCard setCreditCards={setData} setStep={setStep} creditCards={data} />}
    </>
  );
}
