import { useEffect } from 'react';
import { useOrderPreparingContext } from 'src/modules/liveOrder/context';
import { useBasketContext } from 'src/modules/basket';

export function useSetOrderToPreparing({ orderId, percentage }) {
  const { setOrderPreparing } = useOrderPreparingContext();
  const { resetBasket } = useBasketContext();

  useEffect(() => {
    if (percentage >= 100) {
      setOrderPreparing({ loading: false, data: orderId });
      resetBasket();
    }
  }, [percentage]);
}
