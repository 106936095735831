import React, { useMemo } from 'react';
import { useTheme } from 'src/modules/card/context/theme';
import { Text } from 'src/common-ui';
import { useField } from 'formik';
import { useReadContext } from 'src/modules/card/context';
import { map } from 'lodash';
import { ItemRadioGroup } from '../modules/order/components/fields/RadioGroup';

type PaymentMethodFieldProps = {
  name?: string;
  paddingTop?: string;
};

function getPaymentActive(paymentMode) {
  const paymentTypes = {
    card: {
      label: 'Carte de crédit',
      value: 'card',
      icon: '/img/icon/creditCard.svg',
      width: '30px',
    },
    // googlePay: {
    //   label: 'Google Pay',
    //   value: 'googlePay',
    //   icon: '/img/icon/googlePay.svg',
    //   width: '60px',
    // },
    // applePay: {
    //   label: 'Apple Pay',
    //   value: 'applePay',
    //   icon: '/img/icon/applePay.svg',
    //   width: '60px',
    // },
  };
  return map(paymentMode, (v, k) => (v === true ? paymentTypes[k] : null)).filter(v => v != null);
}

function usePaymentActive() {
  const { paymentMode }: any = useReadContext();
  const paymentActive = useMemo(() => getPaymentActive({ ...paymentMode, googlePay: true, applePay: true }), [paymentMode]);
  return paymentActive;
}

export function PaymentMethodField({ name, paddingTop = '3em' }: PaymentMethodFieldProps) {
  const theme = useTheme();
  const paymentActive = usePaymentActive();
  const [field] = useField(name);

  return (
    <>
      <Text
        fontWeigth="bold"
        width="100%"
        justifyContent="flex-start"
        color={theme?.color?.title}
        marginLeft="20px"
        paddingBottom=".2em"
        paddingTop={paddingTop}
      >
        Mode de paiement
      </Text>
      <ItemRadioGroup
        name={name}
        allowEmptySelection={false}
        items={paymentActive}
        theme={theme}
        current={field.value}
        onChange={field.onChange}
      />
    </>
  );
}
