import React from 'react';
import { ButtonLink } from 'src/common-ui';
import copy from 'copy-to-clipboard';

export function ButtonShare({ shareURL, style = {}, onCopy = () => {} }) {
  const [copied, setCopied] = React.useState(false);

  const share = () => {
    if ((navigator as any).share) {
      (navigator as any)
        .share({ title: 'kards', text: 'partage la note avec Kards', url: shareURL })
        .then(() => console.log('Successful share'))
        .catch(error => console.log('Error sharing', error));
    } else {
      copy(shareURL);
      setCopied(true);
    }
    onCopy();
  };

  return (
    <>
      <ButtonLink to="#" onClick={share} style={style} data-cy="share-link">
        Partager
      </ButtonLink>
      {copied && (
        <div data-cy="success-share" style={{ color: 'red', marginTop: '5px', textAlign: 'center' }}>
          Le lien à bien été copié.
        </div>
      )}
    </>
  );
}
