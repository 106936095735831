import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ShareLink } from './1-ShareLink';
import { ShareBillPaymentChoice } from './2-PaymentChoice';
import { ShareBillPayment } from './3-ShareBillPayment';
import { ShareBillPaymentStatus } from './4-ShareBillPaymentStatus';

export function ShareRoutes() {
  return (
    <Switch>
      <Route path="/bill/share/share-link/:id">
        <ShareLink />
      </Route>
      <Route path="/bill/share/payment-choose/:id">
        <ShareBillPaymentChoice />
      </Route>
      <Route path="/bill/share/payment/:id">
        <ShareBillPayment />
      </Route>
      <Route path="/bill/share/payment-status/:id">
        <ShareBillPaymentStatus />
      </Route>
    </Switch>
  );
}
