import React, { useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { FormPayment } from '../../stripe';
import { dividePayment } from '../../../data/accessor/api';
import { Error } from 'src/common-ui';
import { toEuro } from '../utils';

export function DivideBillPayment() {
  const history = useHistory();
  const { id } = useParams();
  const { state }: any = useLocation();
  const [error, setError] = useState();

  const submitRegister = async ({ paymentMethod }) => {
    try {
      const result = await dividePayment({ id, paymentMethod, payWithRestIfExist: state?.paidRestOfBill });
      if (result.data.error) {
        setError(result.data);
        return;
      }
      history.push(`/bill/divide/payment-status/${id}`);
    } catch (err) {
      console.log(err);
      setError(err);
    }
  };

  if (error) return <Error error={error} resetError={() => history.goBack()} />;

  return (
    <FormPayment
      variant={state.payment as 'card' | 'googlePay' | 'applePay'}
      googlePayInfos={{
        label: 'Kards total',
        amount: state.amountToPay,
      }}
      onSubmitRegister={submitRegister as any}
      onSubmitNewCard={submitRegister as any}
      title="Payer"
      price={toEuro(state?.amountToPay)}
      displayNumber={false}
    />
  );
}
