import React from 'react';
import { IonItem, IonLabel, IonIcon } from '@ionic/react';
import { checkmarkCircleOutline, ellipseOutline } from 'ionicons/icons';
import { toEuro } from '../utils';
import { Basket } from 'src/modules/basket';
import { useTheme } from 'src/modules/card/context/theme';

type ProductProps = {
  product: Basket;
  checked: boolean;
  onChange: (checked: boolean) => void;
  disabled: boolean;
};

export function Product({ product, onChange, disabled, checked, ...props }: ProductProps) {
  const theme = useTheme()
  return (
    <IonItem disabled={disabled} onClick={() => onChange(!checked)} lines="full" color={theme?.color?.background}>
      <IonLabel>
        <span style={{ textDecoration: disabled ? 'line-through' : 'none' }}>{product.reference}</span>
      </IonLabel>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ marginRight: '10px', fontSize: '18px', fontWeight: 'bold' }}>{`${toEuro(product.price)}`}</div>
        <IonIcon size="large" color={theme?.color?.title} icon={!disabled && checked ? checkmarkCircleOutline : ellipseOutline} />
      </div>
    </IonItem>
  );
}
